<template>
  <form class="search" @submit.prevent="submit">
    <input type="text" :placeholder="placeholder" v-model="term" />
    <button><SvgSprite symbol="ui-search" size="16" /></button>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    route: {
      type: String,
      default: 'WikiSearch',
    },
    category: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Rechercher',
    },
    list: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const term = ref('')

    const submit = () => {
      router.push({
        name: props.route,
        params: {
          term: term.value,
          category: props.category,
          list: props.list,
        },
      })
      console.log('category', props.route)

      if (props.route === 'LibrarySearch') {
        push({
          name: 'search-animation',
          category: 'keywords-animation',
          action: 'search',
          label: term.value,
        })
      } else if (props.route === 'WikiSearch') {
        push({
          name: 'search-wiki',
          category: 'keywords-wiki',
          action: 'search',
          label: term.value,
        })
      }
    }

    return {
      term,
      submit,
    }
  },
})
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;

  button {
    @include center-y;
    right: 5px;
    border: 0;
    background: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    svg {
      margin-top: 4px;
      fill: $tangerine;
    }
  }

  ::placeholder {
    font-size: 1.6rem;
    line-height: 1.4;
    color: $hippie-blue;
  }

  input {
    border: 0;
    padding: 1.05rem 1.2rem;
    background: $c-white !important;
    font-size: 1.6rem;
    line-height: 1.4;
    color: $hippie-blue !important;
    width: 100%;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $athens-gray inset !important;
  }
}
</style>
