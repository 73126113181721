
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    route: {
      type: String,
      default: 'WikiSearch',
    },
    category: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Rechercher',
    },
    list: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const term = ref('')

    const submit = () => {
      router.push({
        name: props.route,
        params: {
          term: term.value,
          category: props.category,
          list: props.list,
        },
      })
      console.log('category', props.route)

      if (props.route === 'LibrarySearch') {
        push({
          name: 'search-animation',
          category: 'keywords-animation',
          action: 'search',
          label: term.value,
        })
      } else if (props.route === 'WikiSearch') {
        push({
          name: 'search-wiki',
          category: 'keywords-wiki',
          action: 'search',
          label: term.value,
        })
      }
    }

    return {
      term,
      submit,
    }
  },
})
